<template>
  <div class="logout">
    ... logging out ...
  </div>
</template>

<script>
import { Backend } from '@/utilities/Backend'
import { store } from '@/store'

export default {
  name: 'Logout',
  mounted () {
    Backend.post('/logout')
        .then(() => {
          let routeMessage = 'Successfully logged out.'
          store.auth.isAuthenticated = false
          this.$router.push({ name: 'Login', params: { routeMessage } })
        })
  }
}
</script>

<style scoped>

</style>
