<template>
  <div class="subscription-list">
    <h2>Registered Subscriptions</h2>
    <p>{{ message }}</p>
    <table>
      <thead>
      <tr>
        <th>Subscriber</th>
        <th>Publisher</th>
        <th>Event Name</th>
        <th>Status</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="subscription in subscriptions" v-bind:key="subscription">
        <td>{{ subscription.subscriber }}</td>
        <td>{{ subscription.publisher }}</td>
        <td>{{ subscription.eventName }}</td>
        <td>{{ subscription.status }}</td>
        <td>
          <button v-if="subscription.status === 'active'" v-on:click="cancel(subscription.id)">Cancel</button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { Backend } from '@/utilities/Backend'
import { CancelSubscription } from '@/models/commands/CancelSubscription'

export default {
  name: 'SubscriptionList',
  props: ['reload'],
  data () {
    return {
      subscriptions: [],
      message: ''
    }
  },
  methods: {
    loadSubscriptions () {
      Backend.get('/api/subscription-list')
          .then(response => {
            this.subscriptions = response.data
          })
          .catch(error => {
            this.message = error.response.data.message
          })
    },
    cancel (id) {
      Backend.post('/api/cancel-subscription', new CancelSubscription(id))
          .then(() => {
            this.loadSubscriptions()
            this.message = 'Subscription cancelled.'
          })
          .catch(error => {
            this.message = error.response.data.message
          })
    }
  },
  created () {
    this.loadSubscriptions()
  },
  watch: {
    reload () {
      this.loadSubscriptions()
    }
  }
}
</script>

<style scoped>
table {
  text-align: left;
  margin: 0 auto 0 auto;
}

</style>
