import { v4 as uuid } from 'uuid'

export class RegisterSubscription {
  constructor (id, subscriber, publisher, eventName) {
    this.id = id
    this.subscriber = subscriber
    this.publisher = publisher
    this.eventName = eventName
  }

  static new () {
    return new RegisterSubscription(uuid(), null, null, null)
  }
}
