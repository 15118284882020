import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { Backend } from '@/utilities/Backend'
import { store } from '@/store'

createApp(App).use(router).mount('#app')

Backend.init(() => {
  store.auth.isAuthenticated = false
  let routeMessage = 'Login expired, please login again.'
  router.push({ name: 'Login', params: { routeMessage } })
})
