<template>
  <div class="register-subscription">
    <form v-on:submit.prevent="registerSubscription" v-on:keypress.enter.prevent="registerSubscription" method="POST">
      <h2>Register New Subscription</h2>
      <p>{{ message }}</p>
      <label for="subscriber">Subscriber
        <select v-model="command.subscriber" id="subscriber" autofocus>
          <option v-for="context in model.contexts" :value="context" :key="context">{{ context }}</option>
        </select>
      </label> <br> <br>
      <label for="publisher">Publisher
        <select v-model="command.publisher" id="publisher">
          <option v-for="context in model.contexts" :value="context" :key="context">{{ context }}</option>
        </select>
      </label> <br> <br>
      <label for="event-name">Event Name
        <select v-model="command.eventName" id="event-name">
          <option v-for="event in model.events" :value="event" :key="event">{{ event }}</option>
        </select>
      </label> <br> <br>
      <button type="submit">Register</button>
    </form>
  </div>
</template>

<script>
import { RegisterSubscription } from '@/models/commands/RegisterSubscription'
import { Backend } from '@/utilities/Backend'
import { RegisterSubscriptionViewModel } from '@/models/view-models/RegisterSubscriptionViewModel'

export default {
  name: 'RegisterSubscription',
  emits: ['registrationSuccessful'],
  data () {
    return {
      model: new RegisterSubscriptionViewModel(),
      command: RegisterSubscription.new(),
      message: 'Please fill out the form',
    }
  },
  methods: {
    loadViewModel () {
      Backend.get('/api/register-subscription')
          .then(response => {
            this.model = new RegisterSubscriptionViewModel(response.data.events, response.data.contexts)
          })
          .catch(error => {
            this.message = error.response.data.message
          })
    },
    registerSubscription () {
      this.message = '... submitting request ... '
      Backend.post('/api/register-subscription', this.command)
          .then(response => {
            this.message = response.data.message
            this.command = RegisterSubscription.new()
            document.getElementById('subscriber').focus()
            this.$emit('registrationSuccessful')
          })
          .catch(error => {
            this.message = error.response.data.message
          })
    }
  },
  created () {
    this.loadViewModel()
  }
}
</script>

<style scoped>

</style>
