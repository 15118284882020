<template>
  <div class="login">
    <p>{{ routeMessage }}</p>

    <h1>Login</h1>

    <p>{{ message }}</p>
    <form v-on:submit.prevent="login" v-on:keydown.enter.prevent="login">
      <label for="subscriber">E-Mail
        <input v-model="email" type="text" id="subscriber">
      </label> <br> <br>
      <label for="publisher">Password
        <input v-model="password" type="password" id="publisher">
      </label> <br> <br>

      <button type="submit">Login</button>
    </form>

  </div>
</template>

<script>
import { Backend } from '@/utilities/Backend'
import { store } from '@/store'

export default {
  name: 'Login',
  props: ['routeMessage'],
  data () {
    return {
      message: '',
      email: '',
      password: '',
    }
  },
  methods: {
    login () {
      Backend.login('/login', {
        email: this.email,
        password: this.password
      })
          .then(() => {
            store.auth.isAuthenticated = true
            this.email = ''
            this.password = ''
            this.$router.push({ name: 'Home' })
          })
          .catch(error => {
            store.auth.isAuthenticated = false
            this.message = error.response.data.message
            this.email = ''
            this.password = ''
          })
    }
  }
}
</script>

<style scoped>

</style>
