<template>
  <div class="subscriptions">

    <h1>Subscriptions</h1>

    <subscription-list :reload="triggerReload"></subscription-list>

    <register-subscription v-on:registrationSuccessful="triggerReload = !triggerReload"></register-subscription>

  </div>
</template>

<script>
import SubscriptionList from '@/components/Subscription/SubscriptionList'
import RegisterSubscription from '@/components/Subscription/RegisterSubscription'

export default {
  name: 'Subscriptions',
  components: { RegisterSubscription, SubscriptionList },
  data () {
    return {
      triggerReload: false
    }
  }
}
</script>

<style scoped>


</style>
