import axios from 'axios'

export class Backend {

  static axios = null
  static initialized = false
  static onSessionExpired

  static init (onSessionExpired) {
    this.axios = axios.create()
    this.axios.defaults.withCredentials = true
    this.axios.defaults.baseURL = process.env.VUE_APP_EVENT_MESSAGING_URL
    this.axios.defaults.headers.post['Accept'] = 'application/json'
    this.onSessionExpired = onSessionExpired
  }

  static login (route, credentials) {
    return new Promise((resolve, reject) => {
      this.axios.get('/sanctum/csrf-cookie')
        .then(() => {
          this.initialized = true
          this.axios.post(route, credentials)
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  static post (route, command) {
    if (this.initialized === false) {
      throw new Error('Backend connection is not initialized.')
    }
    return new Promise((resolve, reject) => {
      this.axios.post(route, command)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.onSessionExpired()
          }
          reject(error)
        })
    })
  }

  static get (route, query) {
    if (this.initialized === false) {
      throw new Error('Backend connection is not initialized.')
    }
    return new Promise((resolve, reject) => {
      this.axios.get(route, query)
        .then(response => { resolve(response) })
        .catch(error => {
          if (error.response.status === 401) {
            this.onSessionExpired()
          }
          reject(error)
        })
    })
  }

  // TODO with jwt option
}
