import { createRouter, createWebHistory } from 'vue-router'
import NotFoundComponent from '@/components/Utilities/NotFoundComponent'
import Subscriptions from '@/views/Subscriptions'
import Login from '@/views/Login'
import Logout from '@/views/Logout'
import { store } from '@/store'

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/subscriptions'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    props: true
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    component: Subscriptions
  },
  {
    // catch all
    path: '/:pathMatch(.*)',
    component: NotFoundComponent
  }
]

// pretty URLs; backend must redirect everything to 'index.html'
const router = createRouter({
  history: createWebHistory(),
  routes: routes
})

// Navigation Guard
router.beforeEach(async (to) => {
  if (
    // make sure the user is authenticated
    !store.auth.isAuthenticated &&
    // ❗️ Avoid an infinite redirect
    to.name !== 'Login'
  ) {
    // redirect the user to the login page
    return { name: 'Login' }
  }
})

export default router
