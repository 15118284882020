<template>
  <div>
    404. Page not found.
  </div>
</template>

<script>
export default {
  name: 'NotFoundComponent'
}
</script>

<style scoped>

</style>
